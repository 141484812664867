import React from 'react';
import { Chrono } from 'react-chrono';
import  './dr-pepe-chronology.css';
import Header from '../Header/Header';
import FooterMiniMini from '../FooterMiniMini/FooterMiniMini';

export default function DrPepeChronology() {

  const items = [
    {
      title: "26 August to 06 September 2024",
      cardTitle: "Landing Page Creation",
      cardSubtitle: "Quote: 1900€ - Days: 10 - Hourly Rate: 23.75€",
      cardDetailedText: "Designed in Figma and developed with React.js, I created six sections: header, hero, video comparison, Why drpepe.ai is better?, tokenomics, and footer. For the video comparison section, I'm using Cloudflare CDN. For the footer section and newsletter CTA, I used GetResponse and created a proxy server to consume the API. The website is deployed using GitHub and Vercel. All the Pepe JPEGs were vectorized and color-corrected. Implemented i18n to translate the website into English, Chinese, and Korean",

    },
    
    {
      title: "30 September to October 25",
      cardTitle: "Referral System Development",
      cardSubtitle: "Quote: 4900€ - Days: 20 - Hourly Rate: 30.62€ ",
      cardDetailedText: "Built a referral system using React and Solana Web3.js, integrating Phantom Wallet. Allowed users to generate referral links. Used @solana/wallet-adapter-react to connect the wallet. Utilized Firebase to save and track referrers and referrals. The first two weeks were dedicated to learning about Solana CLI, programs, accounts, Rust basics, and React.js integrations. Built a leaderboard that display who referr the most. Leaderboard also shows points awarded to users.",
    },
    {
      title: "04 November to November 13",
      cardTitle: "Social Tasks",
      cardSubtitle: "Quote: 2600€ - Days: 8 - Hourly Rate: 40.62€",
      cardDetailedText: "Users complete social tasks, like following on Twitter/X and joining Telegram, to earn rewards/points and strengthen the network. With secure Firestore integration and social media API connections, user progress is tracked, supporting growth and engagement in a connected ecosystem.",
    },
    {
      title: "November 14",
      cardTitle: "6 Months Contract",
      cardSubtitle: "Quote: 2000€ - 20hr a Week",
      cardDetailedText: "",
    },
    {
      title: "November 14 to November 20",
      cardTitle: "Multilevel Referral",
      cardSubtitle: "20hr",
      cardDetailedText: "The multilevel feature calculates referral points: Level 1 earns 1 point each, Level 2 unique referrals earn 0.5 points. The code processes Firestore data, applies rules from a start date, and visualizes data using Recharts for clear insights.",
    },
    {
      title: "November 20 to November 26",
      cardTitle: "ArDrive & Blog creation",
      cardSubtitle: "20hr",
      cardDetailedText: "Developed a URL path titled 'immortality' for the ArDrive database, integrating an iframe for user interaction. Additionally, within the same week, designed a Matrix-inspired cyberculture blog, currently showcasing one published article."
    },
    {
      title: "November 26 to December 12",
      cardTitle: "Blog Forum",
      cardSubtitle: "40hr",
      cardDetailedText: "Feature cancelled, reason: 'forget about the forum', now we want to do a Full Re-Design of the Website based on Cargo",
    },
    {
      title: "December 16 ",
      cardTitle: "Full Re-Design of the Website",
      cardSubtitle: "On Hold",
      cardDetailedText: "> Mischa0x | VaultCraft I WILL NEVER DM YOU: btw, i think we're going to be using Cargo goin forward for the site > Mischa0x | VaultCraft I WILL NEVER DM YOU:looks like we dont need a custom site",
    },
    {
      title: "January 12 2025 ",
      cardTitle: "OPENAI API Integration",
      cardSubtitle: "35 Euros rate x 6hr worked = 210 Euros",
      cardDetailedText: "Replicated the ChatGPT UI and integrated the OpenAI API to generate responses. The user can input text and receive a response from the AI model. The project was built using React.js,  work was put on hold due to the client's request to focus on other tasks.",
    },
    {
      title: "January 18 2025 ",
      cardTitle: "6 Months Contract",
      cardSubtitle: "Clarification",
      cardDetailedText: "Also we agreed a 6 month contract for 20hr a week / 2000 Euro, but I have the impresion this agreement is nonexistent ??I refused other jobs because I thought I was working for pepe for the next 6 months. Let me know where do you stand/ what do you think is the best way for us to work. ThanksDr. Pepe Ai | I will never DM you, [18. Jan 2025 at 19:06:58]:sorry, this should have been adresseswe switched the siteand started working on the LLM ourselves so will have to think of how we move forward here as that agreement is not longer valid",
    },
  ];

  return (
    <>
    <Header />
    <div className="dr-pepe-chronology-container">
      <Chrono 
        items={items} 
        mode="VERTICAL_ALTERNATING" 
      
        theme={{
          primary: "#3d3de7",
          secondary: "#f5f5f5",
          cardBgColor: "#f5f5f5",
          cardForeColor: "#1C1C1C", 
          cardTitleColor:"#3d3de7",
          titleColor: "#1C1C1C",  
          titleColorActive: "#3d3de7", 
        }}
      />
    </div>
    <FooterMiniMini />
    </>
  );
}
