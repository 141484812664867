import React from 'react';
import { Chrono } from 'react-chrono';
import  './Chronologies.css';
import Header from '../../components/Header/Header';
import FooterMiniMini from '../../components/FooterMiniMini/FooterMiniMini';

export default function DrPepeChronology() {

  const items = [
    {
      title: "DRPEPE.AI",
      cardTitle: "Design & Development",
      cardSubtitle: "26.08.2024 - 12.01.2025",
      cardDetailedText: "Designed in Figma and developed with React.js ",
      url:"https://victormaragioglio.com/chronologies/drpepe",
    },
    

  ];

  return (
    <>
    <Header />
    <div className="chronologies-container">
      <Chrono 
        items={items} 
        mode="VERTICAL_ALTERNATING" 
      
        theme={{
          primary: "#3d3de7",
          secondary: "#f5f5f5",
          cardBgColor: "#f5f5f5",
          cardForeColor: "#1C1C1C", 
          cardTitleColor:"#3d3de7",
          titleColor: "#1C1C1C",  
          titleColorActive: "#3d3de7", 
        }}
      />
    </div>
    <FooterMiniMini />
    </>
  );
}
